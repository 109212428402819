import PropTypes from 'prop-types';
import { List, ListSubheader } from '@mui/material';
import { DashboardSidebarItem } from './DashboardSidebarItem';

const renderNavItems = ({
  depth = 0, items = [], path, isMenuCollapsed,
}) => (
  <List disablePadding>
    {items.reduce(
      // eslint-disable-next-line no-use-before-define
      (acc, item) => reduceChildRoutes({
        acc,
        depth,
        item,
        path,
        isMenuCollapsed,
      }),
      [],
    )}
  </List>
);

const buildShortTitle = (title) => title.split(' ').reduce((shortTitle, str) => `${shortTitle}${str.slice(0, 3)}. `, '');

const reduceChildRoutes = ({
  acc, depth, item, path, isMenuCollapsed,
}) => {
  const key = `${item.title}-${depth}`;
  const partialMatch = item.path ? path.includes(item.path) : false;
  const exactMatch = path.split('?')[0] === item.path; // We don't compare query params

  if (item.children) {
    acc.push(
      <DashboardSidebarItem
        active={partialMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={partialMatch}
        path={item.path}
        title={isMenuCollapsed && item.title?.length > 8 ? buildShortTitle(item.title) : item.title}
        disabled={item.disabled}
        isMenuCollapsed={isMenuCollapsed}
      >
        {renderNavItems({
          depth: depth + 1,
          items: item.children,
          path,
          isMenuCollapsed,
        })}
      </DashboardSidebarItem>,
    );
  } else {
    acc.push(
      <DashboardSidebarItem
        active={exactMatch}
        chip={item.chip}
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        path={item.path}
        title={item.title}
        disabled={item.disabled}
        isMenuCollapsed={isMenuCollapsed}
      />,
    );
  }

  return acc;
};

export function DashboardSidebarSection(props) {
  const {
    items, path, title, isMenuCollapsed, ...other
  } = props;

  return (
    <List
      sx={{
        px: 1,
      }}
      subheader={(
        <ListSubheader
          disableGutters
          disableSticky
          sx={{
            color: 'neutral.500',
            fontSize: isMenuCollapsed ? '0.7rem' : '0.875rem',
            fontWeight: 700,
            textAlign: isMenuCollapsed ? 'center' : undefined,
          }}
        >
          {isMenuCollapsed && title?.length > 8 ? buildShortTitle() : title}
        </ListSubheader>
      )}
      {...other}
    >
      {renderNavItems({
        items,
        path,
        isMenuCollapsed,
      })}
    </List>
  );
}

DashboardSidebarSection.propTypes = {
  items: PropTypes.array.isRequired,
  path: PropTypes.string.isRequired,
  title: PropTypes.string,
};
