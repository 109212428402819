import { useState } from 'react';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import {
  Box, Button, Collapse, Divider, ListItem,
} from '@mui/material';
import { ChevronDown as ChevronDownIcon } from '../../icons/chevron-down';
import { ChevronUp } from '../../icons/chevron-up';
import { uiColours } from '../../theme/base-theme-options';

export function DashboardSidebarItem(props) {
  const {
    active,
    children,
    chip,
    depth,
    icon,
    info,
    open: openProp,
    path,
    title,
    disabled,
    isMenuCollapsed,
    ...other
  } = props;
  const [open, setOpen] = useState(!!openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const renderChevron = () => (!open ? <ChevronDownIcon fontSize="small" /> : <ChevronUp fontSize="small" />);

  if (children) {
    return (
      <>
        <Divider
          sx={{
            position: 'relative',
            left: '-8px',
            width: 'calc(100% + 16px)',
          }}
          color={uiColours.slate.darker}
        />
        <ListItem
          disableGutters
          sx={{
            display: 'block',
            my: 0.5,
            py: 0,
          }}
          {...other}
        >
          <Button
            endIcon={isMenuCollapsed ? null : renderChevron()}
            disableRipple
            onClick={handleToggle}
            startIcon={icon}
            sx={{
              left: '-8px',
              mt: 0.5,
              px: isMenuCollapsed ? 0.5 : 2,
              justifyContent: 'flex-start',
              textAlign: isMenuCollapsed ? 'center' : 'left',
              fontSize: '12px',
              textTransform: 'uppercase',
              height: '30px',
              width: 'calc(100% + 16px)',
              borderRadius: 0,
              lineHeight: 1,
              color: uiColours.slate.darker,
              '& .MuiButton-startIcon': {
                color: active ? 'sidebar.menuItemActiveColor' : 'neutral.400',
              },
              '& .MuiButton-endIcon': {
                color: 'neutral.400',
              },
            }}
          >
            <Box sx={{ flexGrow: 1 }}>{title}</Box>
            {info}
          </Button>
          <Collapse in={open} sx={{ mt: 0.5 }}>
            {children}
          </Collapse>
        </ListItem>
        <Divider
          sx={{
            position: 'relative',
            left: '-8px',
            width: 'calc(100% + 16px)',
            '& + .MuiDivider-root': {
              display: 'none',
            },
          }}
          color={uiColours.slate.darker}
        />
      </>
    );
  }

  // Leaf
  return (
    <ListItem
      disableGutters
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: isMenuCollapsed ? 'center' : 'stretch',
        my: 0.5,
        py: 0,
      }}
    >
      <NextLink href={path} passHref>
        <Button
          disabled={disabled}
          component="a"
          startIcon={icon}
          endIcon={chip}
          disableRipple
          sx={{
            px: depth === 1 && !isMenuCollapsed ? 4 : 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: 1,
            color: disabled ? '#uiSidebar.menuButton.disabled.color !important' : 'uiSidebar.menuButton.default.color',
            textTransform: 'none',
            width: isMenuCollapsed ? '50px' : 'auto',
            height: '50px',
            minWidth: 'auto',
            ...(active && {
              backgroundColor: 'uiSidebar.menuButton.active.background',
              color: 'uiSidebar.menuButton.active.color',
              fontWeight: 'fontWeightBold',
            }),
            lineHeight: 1,
            '& .MuiButton-startIcon': {
              marginRight: isMenuCollapsed ? 0 : '1em',
              ml: 0,
              color: 'inherit',
            },
            '&:hover': {
              backgroundColor: 'uiSidebar.menuButton.hover.background',
            },
          }}
        >
          {isMenuCollapsed ? null : <span style={{ flexGrow: 1, lineHeight: 'initial' }}>{title}</span>}

          {isMenuCollapsed ? null : info}
        </Button>
      </NextLink>
    </ListItem>
  );
}

DashboardSidebarItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  depth: PropTypes.number.isRequired,
  icon: PropTypes.node,
  info: PropTypes.node,
  open: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
};

DashboardSidebarItem.defaultProps = {
  active: false,
  open: false,
};
