import { useEffect, useMemo } from 'react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box, Divider, Drawer, useMediaQuery, Button, Tooltip,
} from '@mui/material';

// Icons
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import EmailIcon from '@mui/icons-material/Email';
// import DisplaySettingsIcon from '@mui/icons-material/DisplaySettings';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import ListIcon from '@mui/icons-material/List';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import TaskIcon from '@mui/icons-material/Task';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';

import { Cog as CogIcon } from '../../icons/cog';
import { PAGE_URLS, MENU_SECTIONS } from '../../constants';
import { DashboardSidebarSection } from '../Dashboard/DashboardSidebarSection';
import { Scrollbar } from '../Scrollbar';
import { Logo } from '../Logo';
import { uiColours } from '../../theme/base-theme-options';
import { OverviewIcon } from '../../icons/overview';
// import { ChurnIcon } from '../../icons/churn';
import { LabelIcon } from '../../icons/label';
import { HomeIcon } from '../../icons/home';
import { CalendarIcon } from '../../icons/calendar';
import { Collection } from '../../icons/collection';
import { DefineIcon } from '../../icons/define';

const addTooltipToIcon = (items) => items.map((item) => ({
  ...item,
  icon: (
    <Tooltip title={item.title} placement="right">
      {item.icon}
    </Tooltip>
  ),
}));

// TODO: Cover by tests [L]
const getMenuSections = (t, { user = {} }) => {
  const allowedApps = user?.settings?.apps || [];

  const isAllSectionsShow = allowedApps.includes(MENU_SECTIONS.ALL);
  const isDashboardSectionsShow = allowedApps.includes(MENU_SECTIONS.DASHBOARD);
  const isPriceSectionsShow = allowedApps.includes(MENU_SECTIONS.PRICE);
  const isMindSectionsShow = allowedApps.includes(MENU_SECTIONS.MIND);
  const isSendSectionsShow = allowedApps.includes(MENU_SECTIONS.SEND);

  const primarySections = addTooltipToIcon([
    {
      title: t('Home'),
      path: PAGE_URLS.HOME,
      icon: <HomeIcon fontSize="small" />,
    },
    {
      title: t('Contract Performance'),
      path: PAGE_URLS.CONTRACT_PERFORMANCE,
      icon: <OverviewIcon fontSize="small" />,
    },
  ]);

  const priceMenuSection = {
    title: t('Price Adjustments'),
    children: addTooltipToIcon([
      {
        title: t('Rules'),
        path: `${PAGE_URLS.INCREASE_RULES}/1`,
        icon: <DefineIcon fontSize="small" />,
      },
      {
        title: t('Wholesale prices'),
        path: PAGE_URLS.WHOLESALE_PRICES,
        icon: <LabelIcon fontSize="small" />,
      },
      /* {
        title: t('Churn profiles'),
        path: PAGE_URLS.CHURN_PROFILE,
        icon: <ChurnIcon fontSize="small" />,
      }, */
      {
        title: t('Execute'),
        path: PAGE_URLS.EXECUTE,
        icon: <CalendarIcon fontSize="small" />,
      },
      {
        title: t('Rules mapping'),
        path: PAGE_URLS.RULES_MAPPING,
        icon: <Collection fontSize="small" />,
      },
    ]),
  };

  /* const priceSettingsMenuItem = {
    title: t('Assumptions'),
    path: PAGE_URLS.ASSUMPTIONS,
    icon: <DisplaySettingsIcon fontSize="small" />,
    disabled: true,
  }; */

  const mindMenuSection = {
    title: t('Service'),
    children: addTooltipToIcon([
      {
        title: t('Ticket'),
        path: PAGE_URLS.MIND.TICKET,
        icon: <TaskIcon fontSize="small" />,
      },
      {
        title: t('Label'),
        path: PAGE_URLS.MIND.LABEL,
        icon: <TaskIcon fontSize="small" />,
      },
      {
        title: t('Try'),
        path: PAGE_URLS.MIND.TRY,
        icon: <TaskIcon fontSize="small" />,
      },
      {
        title: t('Benchmark'),
        path: PAGE_URLS.MIND.BENCHMARK,
        icon: <AnalyticsIcon fontSize="small" />,
      },
    ]),
  };

  const sendMenuSection = {
    title: t('Output Management'),
    children: addTooltipToIcon([
      {
        title: t('E-Mails'),
        path: PAGE_URLS.OUTPUT_MANAGEMENT_PLACEHOLDER,
        icon: <EmailIcon fontSize="small" />,
        disabled: true,
      },
      {
        title: t('Documents'),
        path: `${PAGE_URLS.OUTPUT_MANAGEMENT_PLACEHOLDER}`,
        icon: <AttachEmailIcon fontSize="small" />,
        disabled: true,
      },
      {
        title: t('Brands'),
        path: PAGE_URLS.OUTPUT_MANAGEMENT_PLACEHOLDER,
        icon: <AutoAwesomeIcon fontSize="small" />,
        disabled: true,
      },
      {
        title: t('Assign E-Mail to Event'),
        path: PAGE_URLS.OUTPUT_MANAGEMENT_PLACEHOLDER,
        icon: <FlipCameraAndroidIcon fontSize="small" />,
        disabled: true,
      },
      {
        title: t('Log'),
        path: PAGE_URLS.OUTPUT_MANAGEMENT_PLACEHOLDER,
        icon: <ListIcon fontSize="small" />,
        disabled: true,
      },
    ]),
  };

  const settingsMenuSection = {
    title: t('Settings'),
    children: addTooltipToIcon([
      {
        title: t('Configuration'),
        path: PAGE_URLS.CONFIGURATIONS,
        icon: <CogIcon fontSize="small" />,
      },
    ]),
  };

  let sections = [];

  if (isAllSectionsShow || isDashboardSectionsShow) {
    sections = [...primarySections];
  }
  if (isAllSectionsShow || isPriceSectionsShow) {
    sections.push(priceMenuSection);

    // settingsMenuSection.children.push(priceSettingsMenuItem);
  }
  if (isAllSectionsShow || isMindSectionsShow) {
    sections.push(mindMenuSection);
  }
  if (isAllSectionsShow || isSendSectionsShow) {
    sections.push(sendMenuSection);
  }

  sections.push(settingsMenuSection);

  return sections;
};

export function PageSidebar({
  onClose, open, user, isMenuCollapsed, onMenuCollapseClick,
}) {
  const router = useRouter();
  const { t } = useTranslation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
    noSsr: true,
  });
  const sections = useMemo(() => getMenuSections(t, { user }), [t, user]);

  const handlePathChange = () => {
    if (!router.isReady) {
      return;
    }

    if (open) {
      onClose?.();
    }
  };

  useEffect(
    handlePathChange,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router.isReady, router.asPath],
  );

  const content = (
    <Box
      sx={{
        overflowX: 'hidden',
      }}
    >
      <div>
        <Box sx={isMenuCollapsed ? { pt: 2, pb: 1 } : { p: 3 }}>
          <NextLink href="/" passHref>
            <a>
              <Logo slim={isMenuCollapsed} />
            </a>
          </NextLink>
        </Box>
      </div>

      <Divider color={uiColours.slate.darker} />

      <Box sx={{ flexGrow: 1 }}>
        <DashboardSidebarSection
          isMenuCollapsed={isMenuCollapsed && lgUp}
          path={router.asPath}
          sx={{
            px: 1,
            py: 0,
            mt: 1,
            '& + &': {
              mt: 2,
            },
          }}
          items={sections}
        />
      </Box>
    </Box>
  );

  const bottomBlock = (
    <Box
      sx={{
        padding: 1,
        display: 'flex',
        justifyContent: isMenuCollapsed ? 'center' : 'flex-end',
        backgroundColor: 'sidebar.background',
      }}
    >
      <Button
        sx={{
          minWidth: 'auto',
          width: '50px',
          height: '50px',
          padding: 0,
          color: uiColours.slate.darker,
        }}
        onClick={() => onMenuCollapseClick(!isMenuCollapsed)}
      >
        {isMenuCollapsed ? (
          <KeyboardDoubleArrowRightIcon fontSize="small" />
        ) : (
          <KeyboardDoubleArrowLeftIcon fontSize="small" />
        )}
      </Button>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            width: isMenuCollapsed ? 70 : 280,
            overflow: 'hidden',
            backgroundColor: 'sidebar.background',
            borderRightColor: 'divider',
            borderRightStyle: 'solid',
            borderRightWidth: (theme) => (theme.palette.mode === 'dark' ? 1 : 0), // TODO: Move "dark" to constants and reuse [L]
            color: '#FFFFFF',
          },
        }}
        variant="permanent"
      >
        <Scrollbar
          forceVisible="y"
          sx={{
            height: 'calc(100% - 66px)',
          }}
        >
          {content}
        </Scrollbar>
        {bottomBlock}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'sidebar.background',
          color: '#FFFFFF',
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
}

PageSidebar.propTypes = {
  onClose: PropTypes.func.isRequired,
  onMenuCollapseClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.shape({}).isRequired,
  isMenuCollapsed: PropTypes.bool.isRequired,
};
