import { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

import { PageNavbar } from './PageNavbar';
import { PageSidebar } from './PageSidebar';
import { useAuth } from '../../hooks/use-auth';
import LocalStorage from '../../utils/localStorage';

// TODO: Move to separate file? [L]
const PageLayoutRoot = styled('div')(({ theme, isMenuCollapsed }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: isMenuCollapsed ? 70 : 280,
  },
}));

export default function PageLayout(props) {
  const { children } = props;
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = LocalStorage.useLocalStorage(
    LocalStorage.KEYS.IS_MENU_COLLAPSED,
    false,
  );
  const { user } = useAuth();

  return (
    <>
      <PageLayoutRoot isMenuCollapsed={isMenuCollapsed}>
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          {children}
        </Box>
      </PageLayoutRoot>
      <PageNavbar onOpenSidebar={() => setIsSidebarOpen(true)} isMenuCollapsed={isMenuCollapsed} />
      <PageSidebar
        user={user}
        onClose={() => setIsSidebarOpen(false)}
        open={isSidebarOpen}
        isMenuCollapsed={isMenuCollapsed}
        onMenuCollapseClick={setIsMenuCollapsed}
      />
    </>
  );
}

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
