import NextLink from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {
  Avatar, Box, Divider, ListItemIcon, ListItemText, MenuItem, Popover, Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../hooks/use-auth';
import { Cog as CogIcon } from '../../icons/cog';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { PAGE_URLS } from '../../constants';

// TODO: Replace all NextLink links with constants [L]
export function AccountPopover(props) {
  const {
    anchorEl, onClose, open, ...other
  } = props;
  const router = useRouter();
  const auth = useAuth(); // TODO: Pass user as a prop from parent and use here? [L]
  const { logout } = useAuth();
  const { t } = useTranslation();

  // TODO: Pass handleLogout as a prop from parent and use here? [L]
  const handleLogout = async () => {
    try {
      onClose?.();
      await logout();
      await router.push(PAGE_URLS.LOGIN);
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom',
      }}
      keepMounted
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box
        sx={{
          alignItems: 'center',
          p: 2,
          display: 'flex',
        }}
      >
        <Avatar
          src={auth?.user?.avatar}
          sx={{
            height: 40,
            width: 40,
          }}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
        <Box
          sx={{
            ml: 1,
          }}
        >
          <Typography variant="body1">{auth?.user?.name}</Typography>
          <Typography color="textSecondary" variant="body2">
            {auth?.user?.country}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ my: 1 }}>
        <NextLink href={PAGE_URLS.PROFILE_DETAILS} passHref>
          <MenuItem component="a">
            <ListItemIcon>
              <UserCircleIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">{t('Profile')}</Typography>} />
          </MenuItem>
        </NextLink>
        <NextLink href={PAGE_URLS.SETTINGS} passHref>
          <MenuItem component="a" disabled>
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={<Typography variant="body1">{t('Settings')}</Typography>} />
          </MenuItem>
        </NextLink>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">{t('Logout')}</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
}

AccountPopover.propTypes = {
  anchorEl: PropTypes.any, // TODO: Use correct type [L]
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
