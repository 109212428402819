import { createSvgIcon } from '@mui/material/utils';

export const DefineIcon = createSvgIcon(
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <line x1="19.58" y1="21.26" x2="3.69" y2="21.26" stroke="black" />
    <line x1="19.58" y1="17.08" x2="3.69" y2="17.08" stroke="black" />
    <line x1="8.12" y1="12.89" x2="3.69" y2="12.89" stroke="black" />
    <line x1="15.92" y1="2.74" x2="15.92" y2="12.21" stroke="black" />
    <polyline points="20.31 9.53 15.92 13.09 11.54 9.53" />
  </svg>,
  'DefineIcon',
);
