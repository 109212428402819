import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  AppBar, Avatar, Badge, Box, ButtonBase, IconButton, Toolbar, Tooltip,
} from '@mui/material';
import { styled } from '@mui/material/styles';

import { Menu as MenuIcon } from '../../icons/menu';
import { Bell as BellIcon } from '../../icons/bell';
import { UserCircle as UserCircleIcon } from '../../icons/user-circle';
import { AccountPopover } from './AccountPopover';
import { NotificationsPopover } from '../Dashboard/NotificationsPopover';
import { LanguagePopover } from '../Dashboard/LanguagePopover';
import { useAuth } from '../../hooks/use-auth';

// TODO: Move to separate file? [L]
const PageNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  ...(theme.palette.mode === 'light'
    ? {
      boxShadow: theme.shadows[3],
    }
    : {
      backgroundColor: theme.palette.background.paper,
      borderBottomColor: theme.palette.divider,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
      boxShadow: 'none',
    }),
}));

// TODO: Move to separate file? [L]
function LanguageButton() {
  const anchorRef = useRef(null);
  const { i18n } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <IconButton onClick={handleOpenPopover} ref={anchorRef} sx={{ ml: 1 }}>
        <Box
          sx={{
            display: 'flex',
            height: 20,
            width: 20,
            '& img': {
              width: '100%',
            },
          }}
        >
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            alt=""
            src={
              LanguagePopover.languageOptions[LanguagePopover.languageOptions[i18n.language] ? i18n.language : 'en']
                .icon // TODO: Move to const and reuse [L]
            }
          />
        </Box>
      </IconButton>
      <LanguagePopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} />
    </>
  );
}

// TODO: Move to separate file? [L]
function NotificationsButton() {
  const anchorRef = useRef(null);
  const [unread, setUnread] = useState(0);
  const [openPopover, setOpenPopover] = useState(false);
  // TODO: Unread notifications should come from a context and be shared with both this component and notifications popover. To simplify the demo, we get it from the popover

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  const handleUpdateUnread = (value) => {
    setUnread(value);
  };

  return (
    <>
      <Tooltip title="Notifications">
        <IconButton ref={anchorRef} sx={{ ml: 1 }} onClick={handleOpenPopover}>
          <Badge color="error" badgeContent={unread}>
            <BellIcon fontSize="small" />
          </Badge>
        </IconButton>
      </Tooltip>
      <NotificationsPopover
        anchorEl={anchorRef.current}
        onClose={handleClosePopover}
        onUpdateUnread={handleUpdateUnread}
        open={openPopover}
      />
    </>
  );
}

// TODO: Move to separate file? [L]
function AccountButton() {
  const anchorRef = useRef(null);
  const auth = useAuth();
  const [openPopover, setOpenPopover] = useState(false);

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClosePopover = () => {
    setOpenPopover(false);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpenPopover}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 2,
        }}
      >
        <Avatar
          sx={{
            height: 40,
            width: 40,
          }}
          src={auth?.user?.avatar}
        >
          <UserCircleIcon fontSize="small" />
        </Avatar>
      </Box>
      <AccountPopover anchorEl={anchorRef.current} onClose={handleClosePopover} open={openPopover} />
    </>
  );
}

export function PageNavbar(props) {
  const { onOpenSidebar, isMenuCollapsed, ...other } = props;

  return (
    <PageNavbarRoot
      sx={{
        left: {
          lg: isMenuCollapsed ? 70 : 280,
        },
        width: {
          lg: isMenuCollapsed ? 'calc(100% - 70px)' : 'calc(100% - 280px)',
        },
      }}
      {...other}
    >
      <Toolbar
        disableGutters
        sx={{
          minHeight: 64,
          left: 0,
          px: 2,
        }}
      >
        <IconButton
          onClick={onOpenSidebar}
          sx={{
            display: {
              xs: 'inline-flex',
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <LanguageButton />
        <NotificationsButton />
        <AccountButton />
      </Toolbar>
    </PageNavbarRoot>
  );
}

PageNavbar.propTypes = {
  onOpenSidebar: PropTypes.func.isRequired,
  isMenuCollapsed: PropTypes.bool.isRequired,
};
