import { createSvgIcon } from '@mui/material/utils';

export const OverviewIcon = createSvgIcon(
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M6.12,12.24v8.98H2.79V12.24h3.33m.31-2H2.49c-.94,0-1.69,.76-1.69,1.69v9.59c0,.94,.76,1.69,1.69,1.69h3.94c.94,0,1.69-.76,1.69-1.69V11.94c0-.94-.76-1.69-1.69-1.69h0Z" />
    <path d="M21.21,12.24v8.98H12V12.24h9.21m.31-2H11.7c-.94,0-1.69,.76-1.69,1.69v9.59c0,.94,.76,1.69,1.69,1.69h9.82c.94,0,1.69-.76,1.69-1.69V11.94c0-.94-.76-1.69-1.69-1.69h0Z" />
    <path d="M21.2,2.78v3.65H2.79V2.78H21.2m.31-2H2.49c-.94,0-1.69,.76-1.69,1.69V6.73c0,.94,.76,1.69,1.69,1.69H21.51c.94,0,1.69-.76,1.69-1.69V2.47c0-.94-.76-1.69-1.69-1.69h0Z" />
  </svg>,
  'OverviewIcon',
);
