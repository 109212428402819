import { createSvgIcon } from '@mui/material/utils';

export const HomeIcon = createSvgIcon(
  <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <polyline points="1.12 11.05 12 2.21 22.88 11.05" fill="none" stroke="black" />
    <polyline
      points="3.58 9.95 3.58 21.79 9.03 21.79 9.03 14.4 14.97 14.4 14.97 21.79 20.42 21.79 20.42 9.95"
      fill="none"
      stroke="black"
    />
  </svg>,
  'HomeIcon',
);
