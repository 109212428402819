import { createSvgIcon } from '@mui/material/utils';

export const CalendarIcon = createSvgIcon(
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M19.98,4.86c.42,0,.75,.34,.75,.75v13.59c0,.42-.34,.75-.75,.75H4.02c-.42,0-.75-.34-.75-.75V5.61c0-.42,.34-.75,.75-.75h15.96m0-2H4.02c-1.52,0-2.75,1.23-2.75,2.75v13.59c0,1.52,1.23,2.75,2.75,2.75h15.96c1.52,0,2.75-1.23,2.75-2.75V5.61c0-1.52-1.23-2.75-2.75-2.75h0Z" />
    <line x1="2.51" y1="9.79" x2="21.49" y2="9.79" stroke="black" />
    <g>
      <line x1="7.6" y1="6.44" x2="7.6" y2="2.05" stroke="black" />
      <line x1="16.5" y1="6.44" x2="16.5" y2="2.05" stroke="black" />
    </g>
  </svg>,
  'CalendarIcon',
);
